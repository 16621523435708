// About.jsx

import { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function About() {
  // Animation controls
  const headingControls = useAnimation();
  const image1Controls = useAnimation();
  const image2Controls = useAnimation();
  const textControls = useAnimation();

  // Intersection Observers
  const [headingRef, headingInView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const [image1Ref, image1InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const [image2Ref, image2InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const [textRef, textInView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  // Trigger animations when elements come into view
  useEffect(() => {
    if (headingInView) {
      headingControls.start('visible');
    }
    if (image1InView) {
      image1Controls.start('visible');
    }
    if (image2InView) {
      image2Controls.start('visible');
    }
    if (textInView) {
      textControls.start('visible');
    }
  }, [
    headingInView,
    headingControls,
    image1InView,
    image1Controls,
    image2InView,
    image2Controls,
    textInView,
    textControls,
  ]);

  // Animation variants
  const headingVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: 'easeOut' },
    },
  };

  const imageVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.6, ease: 'easeOut' },
    },
  };

  const textVariants = {
    hidden: { opacity: 0, x: 50 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.6, ease: 'easeOut' },
    },
  };

  return (
    <div className='container mt-10 mx-auto p-4 max-w-6xl'>
      {/* Animated Heading */}
      <motion.h2
        ref={headingRef}
        variants={headingVariants}
        initial='hidden'
        animate={headingControls}
        className='text-4xl md:text-6xl font-bold pb-6 text-transparent bg-clip-text bg-gray-900 dark:bg-gray-100 text-center sm:text-left'
      >
        About Me
      </motion.h2>

      <div className='max-w-6xl mx-auto sm:justify-center sm:align-middle shadow-xl bg-white dark:bg-gray-950 p-6 rounded-lg dark:shadow-xl'>
        {/* Intro Section */}
        <section className='m-4'>
          <div className='flex flex-col sm:flex-row items-center'>
            {/* Animated Image (visible on small screens first) */}
            <motion.div
              ref={image1Ref}
              variants={imageVariants}
              initial='hidden'
              animate={image1InView ? 'visible' : 'hidden'}
              className='sm:w-1/2'
            >
              <div className='block sm:hidden'>
                <img
                  src='/Handsome.jpg'
                  alt='About Me'
                  className='rounded-full w-48 h-48 mb-4 mx-auto object-cover transform transition-transform duration-500 hover:scale-105'
                />
              </div>

              {/* Animated Text */}
              <motion.h3
                ref={textRef}
                variants={textVariants}
                initial='hidden'
                animate={textInView ? 'visible' : 'hidden'}
                className='text-xl pb-4 text-gray-900 dark:text-white leading-relaxed md:leading-loose'
              >
                Forward-thinking software developer with a bachelor’s degree in
                business management, seamlessly integrating technical expertise
                with strategic insights to drive innovation. Experience in
                launching software products, engineering robust tools
                with full-stack technologies, and refining UI/UX designs while
                actively contributing to agile Scrum processes. As a Certified
                ScrumMaster (CSM), I blend strong programming skills with
                effective leadership and problem-solving to ensure project
                success. Committed to leveraging emerging technologies to unlock
                new business opportunities and fuel sustainable growth.
              </motion.h3>
            </motion.div>

            {/* Image on the right for larger screens */}
            <motion.div
              ref={image2Ref}
              variants={imageVariants}
              initial='hidden'
              animate={image2InView ? 'visible' : 'hidden'}
              className='hidden sm:block sm:w-1/2 pl-8 md:pl-20'
            >
              <img
                src='/Handsome.jpg'
                alt='About Me'
                className='rounded-full w-80 h-80 mb-4 sm:mt-4 md:ml-10 mx-auto object-cover transform transition-transform duration-500 hover:scale-105'
              />
            </motion.div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default About;
